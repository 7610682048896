<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else-if="data">
      <h3 style="margin-bottom: 25px">
        {{ $l.get("plan-code-create.header") }}
      </h3>
      <PlanCodeForm
        :planCode="planCode"
        :plans="data.plans"
        :onSubmit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import SubmitFormInteractor from "@/business/plan-code-create/submit-form";
import InitPlanCodeCreateInteractor from "@/business/plan-code-create/init-plan-code-create";
import PlanCodeCreateScreenController from "@/adapters/controllers/screen-plan-code-create";
import PlanCodeForm from "@/application/components/plans-codes/PlanCodeForm";

import Loader from "@/application/components/Loader";
export default {
  screen_name: "plan-code-create",
  components: { PlanCodeForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        submitForm: null,
        initPlanCodeCreate: null
      },
      isLoading: false,
      data: null,
      planCode: {
        code: "",
        planId: null,
        durationInDays: 366,
        description: ""
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(PlanCodeCreateScreenController);

    //{ INTERACTORS
    this.interactors.initPlanCodeCreate = this.$injector.get(
      InitPlanCodeCreateInteractor
    );
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initPlanCodeCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.planCode);
    }
  }
};
</script>

<style lang="scss"></style>
