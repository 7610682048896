<template>
  <div class="plan-form">
    <form @submit.prevent="onFormSubmit">
      <vs-card style="text-align: center">
        <div slot="header">
          <h3>{{ $l.get("dictionary.code") }}</h3>
        </div>
        <h1>{{ planCode.code }}</h1>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.plan") }}</h3>
        </div>
        <ul class="centerx">
          <li>
            <vs-radio
              style="margin-right: 10px"
              v-model="planCode.planId"
              vs-name="plan"
              :vs-value="plan.id"
              v-for="(plan, idx) in plans"
              :key="idx"
              >{{ plan.name }}</vs-radio
            >
          </li>
        </ul>
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.duration") }}</h3>
        </div>
        <vs-input-number
          v-model="planCode.durationInDays"
          min="1"
          :label="$l.get('dictionary.days') + ':'"
        />
      </vs-card>

      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("dictionary.description") }}</h3>
        </div>
        <vs-textarea
          :label="$l.get('dictionary.description')"
          v-model="planCode.description"
        />
      </vs-card>

      <input
        type="submit"
        style="position: absolute; left: -9999px; width: 1px; height: 1px;"
        tabindex="-1"
      />
    </form>
    <div style="margin-top: 25px; margin-bottom: 25px; text-align: right">
      <vs-button color="primary" type="filled" @click="onFormSubmit()">{{
        $l.get("dictionary.submit-new")
      }}</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "plan-form",
  props: ["planCode", "plans", "onSubmit"],
  data() {
    return {
      isNameInvalid: false
    };
  },
  mounted() {
    if (!this.planCode.code) {
      this.planCode.code = this.generateCode();
    }
  },
  methods: {
    generateCode() {
      const chars = "NTZJ4UFCIRH3EO078BD1YQALKM29S6VX5WPG";
      let code = "";
      let sum = 0;
      for (let i = 0; i < 7; i++) {
        const pos = Math.round((Math.random() * chars.length) % chars.length);
        code += chars.charAt(pos);
        sum += chars.charCodeAt(pos) * (i + 1);
      }
      code += chars.charAt(sum % chars.length);
      return code;
    },
    onFormSubmit() {
      this.onSubmit();
    }
  }
};
</script>

<style lang="scss">
.plan-form .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}

.plan-form .criteria-node {
  cursor: pointer;
  padding: 0 2px;
  min-width: 60px;
  text-align: center;
  font-size: 11px;
  height: 30px;
  border: 2px solid #e8e8e3;
  border-radius: 2px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  background: #fff;
}

.plan-form .criteria-node:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 1px 0 0 1px;
  border: 2px solid #d3d3cb;
}

.plan-form .criteria-node .node-name {
  line-height: 30px;
  color: #9b9b9b;
}

.plan-form .criteria-node:hover .node-name {
  color: #8b8b8b;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
}

// .plan-form .vs-input {
//   margin: 15px;
// }
</style>
